import { observer } from 'mobx-react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../hooks/useStore';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Link,
  Box,
  TextField,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import ConversationFiles from '../../pages/ConversationPage/ConversationFiles';
import { UpdateWorkflowRunDto } from '../../models/Workflows';
import Button from '../buttons/Button';
import Typography from '@mui/material/Typography';
import PrimaryIconButton from '../buttons/PrimaryIconButton';

export interface CreditSpreadingResult {
  [key: string]:
    | {
        value: number;
        page: string | number;
        reason: string;
        ground_truth?: string;
      }
    | 'string'
    | number;
}

const CreditSpreadingResultTable = () => {
  const [workflowResult, setWorkflowResult] = useState<CreditSpreadingResult>({});
  const [editedValues, setEditedValues] = useState<{ [key: string]: number }>({});
  const [isHiddenColumnVisible, setIsHiddenColumnVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    localizationStore: { i18next: i18n },
    workFlowStore: { selectedWorkflowRun, updateRun },
    conversationStore: { setPreviewFileInitialPage, setPreviewFile },
  } = useStore();

  useEffect(() => {
    setWorkflowResult(selectedWorkflowRun.result);
    const initialEditedValues: { [key: string]: number } = {};
    Object.entries(selectedWorkflowRun.result).forEach(([key, item]: any) => {
      initialEditedValues[key] = item.value;
    });
    setEditedValues(initialEditedValues);
    setPreviewFile(selectedWorkflowRun.variables.files[0]);
  }, [selectedWorkflowRun.id]);

  const handleValueChange = (key: string, newValue: number) => {
    setEditedValues(prevState => ({
      ...prevState,
      [key]: newValue,
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const newResultObject = { ...selectedWorkflowRun.result };

      Object.keys(editedValues).forEach(key => {
        if (newResultObject[key]) {
          newResultObject[key].value = editedValues[key];
        }
      });

      const updateRunDto: UpdateWorkflowRunDto = {
        result: newResultObject,
      };

      await updateRun(selectedWorkflowRun.id, updateRunDto);
    } catch (error) {
      console.error('Error updating workflow run:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = (page: string | number) => {
    const parsedPage = typeof page === 'string' ? parseInt(page) : page;

    setPreviewFileInitialPage(parsedPage);
    setPreviewFile(selectedWorkflowRun.variables.files[0]);
  };

  return (
    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} width="100%" minHeight="0" flexGrow={1}>
      <Box width={{ xs: '100%', md: '50%' }} minHeight="0" display="flex" flexDirection="column">
        <ConversationFiles docFiles={selectedWorkflowRun.variables.files} redactedViewDisabled={true} />
      </Box>
      <Box
        width={{ xs: '100%', md: '50%' }}
        pl={{ xs: 0, md: 2 }}
        minHeight="0"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        overflow="hidden"
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', marginBottom: '12px' }}>
          <Typography variant="h6" sx={{ textAlign: 'start' }}>
            Accuracy: {`${workflowResult.accuracy}`}
          </Typography>
          <PrimaryIconButton onClick={() => setIsHiddenColumnVisible(prevState => !prevState)}>
            <Typography variant="body2" sx={{ textAlign: 'start', color: 'white', marginRight: '6px' }}>
              {isHiddenColumnVisible ? 'Hide' : 'Show'} ground truth column
            </Typography>
            {isHiddenColumnVisible ? (
              <VisibilityOffIcon style={{ color: 'white' }} />
            ) : (
              <VisibilityIcon style={{ color: 'white' }} />
            )}
          </PrimaryIconButton>
        </Box>

        <TableContainer
          id={'resultElement'}
          component={Paper}
          style={{ flexGrow: 1, overflow: 'auto', marginTop: '12px' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>{i18n.t('Name')}</TableCell>
                <TableCell>{i18n.t('Value')}</TableCell>
                <TableCell>{i18n.t('Page')}</TableCell>
                <TableCell>{i18n.t('Ground Truth')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(workflowResult).map(([key, item]) => {
                if (typeof item !== 'object') {
                  return null;
                }

                return (
                  <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    <TableCell>
                      <TextField
                        value={editedValues[key]}
                        onChange={e => handleValueChange(key, Number(e.target.value))}
                        variant="standard"
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={item.reason || ''}
                        slotProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: 'rgba(0, 0, 0, 0.8)',
                              color: '#fff',
                              fontSize: theme => theme.typography.pxToRem(12),
                              borderRadius: theme => theme.shape.borderRadius,
                            },
                          },
                        }}
                      >
                        <Link href="#" onClick={() => handlePageClick(item.page)} underline="none">
                          {item.page}
                        </Link>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{isHiddenColumnVisible ? item.ground_truth : '---'}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button color="primary" onClick={handleSave} disabled={loading} loading={loading}>
            {i18n.t('Save')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default observer(CreditSpreadingResultTable);
