import {
  ClickAwayListener,
  IconButton,
  Input,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
  Box as MuiBox,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import StorageIcon from '@mui/icons-material/Storage';

import SendIconWhite from '../../assets/icons/send-message-white.svg';
import SendIconGray from '../../assets/icons/send-message-gray.svg';
import SqlServer from '../../assets/icons/integrations/SqlServerLogo.svg';
import { COLOR_BUTTON_CONTAINED_DISABLED, COLOR_WHITE, GRAY_COLORS } from '../../constants/colors';

import { ConversationTypes } from '../../constants/conversationTypes';
import { fileStatus } from '../../constants/fileStatus';
import { LocalStorageKeys } from '../../constants/localStorageKeys';
import { Paths } from '../../constants/routes';
import { useStore } from '../../hooks/useStore';
import { EMPTY_CHAT } from '../../models/Chat';
import { Conversation, CreateConversationDto, DEFAULT_SETTINGS, INITIAL_CONVERSATION } from '../../models/Conversation';
import { getDocumentsKey } from '../../utils/documentsS3';
import { uploadDriveFile } from '../../utils/uploadFile';
import PrimaryIconButton from '../buttons/PrimaryIconButton';
import UploadDocument from '../fileUpload/UploadDocument';
import Flex from '../utils/flex/Flex';
import Box from '@material-ui/core/Box/Box';
import { OrganizationModel } from '../../models/OrganizationModel';
import { SUPPORTED_INTEGRATIONS } from '../../models/IntegrationModel';
import { Models } from '../../constants/models';

interface MessageInputProps {
  textAreaPlaceholder?: string;
  options: OrganizationModel[];
}

const MessageInput = ({ textAreaPlaceholder, options }: MessageInputProps) => {
  const {
    localizationStore: { i18next: i18n },
    conversationStore: {
      createConversation,
      addChat,
      setInitialConversation,
      currentConversation,
      messageInput,
      setMessageInput,
      shouldInputBeFocused,
      setShouldInputBeFocused,
      conversationSettingsState,
      isSecondaryModelSelected,
      setCompletionMessage,
      redactPiiData,
      setMultipleModelsRedactedChatMessage,
      addFileToConversation,
      setIsUploadInProgress,
      cancelFileUpload,
      setUploadingConversationId,
      setUploadingFileId,
      setUploadProgress,
      dataAnalysisEnablingPending,
      setDataAnalysisEnablingPending,
      addDataAnalysisBotChat,
      setPrimaryConversationModelSettings,
    },
    fileStore: { createFile },
    userStore: { userData },
    uiStore: {
      isStartNewConversationFromPromptOpen,
      toggleOpenAiSettingsSection,
      toggleOpenAiCompareAnswersModalOpen,
      toggleMessageWasBlockedModalOpen,
      toggleIsPromptsDrawerExpanded,
    },
    modelStore: { organizationModels, setSelectedModel, findAllOrganizationModels },
    appState: { googleDriveApi, fileApi },
    integrationsStore: { getIntegrations, integrations, setSelectedSqlConnection, selectedSqlConnection },
  } = useStore();
  const gDriveToken = localStorage.getItem(LocalStorageKeys.googleDriveAccessToken);

  const { chats, files, id: conversationId, isStartedFromKnowledgeBase } = currentConversation;

  //look at all active models and search for a file one
  const noFileModelEnabled = organizationModels
    .filter(model => model.isActive)
    .every(model => !model.isDefaultFileModel);

  const hasUserStartedAskingQuestions =
    chats?.filter(chat => {
      const isFileChat = Array.isArray(chat?.files) && chat?.files?.length > 0 && chat?.files[0].id.length > 0;

      if (isFileChat) {
        // return false as this is not a chat with a message
        return false;
      }

      // if only the data analysis chat exists, we allow the user to upload files
      return chat.message !== i18n.t('conversation.dataAnalysis.notEnabled');
    }).length > 0;

  const [doesChatBelongToCurrentUser, setDoesChatBelongToCurrentUser] = useState(false);

  const { addToast } = useToasts();
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const { model, accuracy, length, providerName } = conversationSettingsState;

  const isFileModel = model?.includes('file');
  const isAgentModel = model?.includes('agent');

  let id: null | string = currentConversation?.user?.id;

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingChatConversationId, setLoadingChatConversationId] = useState('');
  const knowledgeBaseSselectedFile = localStorage.getItem('knowledgeBaseSselectedFile');
  const [showDatabaseTooltip, setShowDatabaseTooltip] = useState(false);

  // Getting all organization models
  useEffect(() => {
    (async () => {
      await findAllOrganizationModels();
    })();
  }, []);

  useEffect(() => {
    setDoesChatBelongToCurrentUser(id ? id === userData.id : true);
  }, [id, userData]);

  const focusOnInput = () => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 500);
  };

  useEffect(focusOnInput, []);

  useEffect(() => {
    if (shouldInputBeFocused) {
      inputRef.current?.focus();
      setShouldInputBeFocused(false);
    }
  }, [shouldInputBeFocused]);

  const handleCreateConversation = async () => {
    if (!messageInput.trim().length) {
      return;
    }

    if (isSecondaryModelSelected) {
      const result = await redactPiiData(messageInput);

      if (result.wasBlocked) {
        setMultipleModelsRedactedChatMessage({ ...EMPTY_CHAT, ...result });
        toggleMessageWasBlockedModalOpen(true);
        return;
      }

      toggleOpenAiCompareAnswersModalOpen(true);
      setCompletionMessage(messageInput);

      return;
    }

    try {
      toggleOpenAiSettingsSection(false);
      setLoading(true);
      setInitialConversation(messageInput, model, providerName, accuracy, length, userData);
      let conversation: Conversation = INITIAL_CONVERSATION;
      if (messageInput.includes('https://docs.google.com/')) {
        await addGoogleDriveLinkMessage(messageInput);
      } else {
        let modelTemp = Number(accuracy);

        const conversationDto: CreateConversationDto = {
          type: ConversationTypes.Chat,
          model: model,
          temperature: modelTemp,
          maxTokens: length,
          providerName,
          chat: {
            message: messageInput,
          },
        };
        // adding SQL connection details to conversation to use it further
        if (selectedSqlConnection.configName) {
          conversationDto.integration = selectedSqlConnection;
        }
        conversation = (await createConversation(conversationDto)) || {
          ...INITIAL_CONVERSATION,
          model: model,
          temperature: modelTemp,
          maxTokens: length,
          providerName,
        };
      }

      if (conversation?.id) {
        navigate(`${Paths.CHAT}/${conversation.id}`, { state: {} });
      }
    } catch (e) {
      console.log('e ------------------->> ', e);
      addToast(i18n.t('common.somethingWentWrong'), { appearance: 'error' });
    } finally {
      setLoading(false);
      focusOnInput();
    }
  };

  const addGoogleDriveLinkMessage = async (fileUrl: string) => {
    const fileName = 'driveFile.pdf';
    const fileKey = getDocumentsKey(userData.id, fileName);
    const createdFileEntry = await createFile({ key: fileKey, name: `${fileUrl}.pdf` });
    if (gDriveToken) {
      try {
        const tokenInfo = await googleDriveApi.getTokenInfo(gDriveToken);
        if (tokenInfo) {
          const conversation = await assignFilesToConversation([
            { id: createdFileEntry.id, name: createdFileEntry.name },
          ]);
          const regex = /[-\w]{25,}/;
          const fileData = fileUrl?.match(regex);
          const fileId = fileData?.length ? fileData[0] : '';

          setMessageInput('');
          setIsUploadInProgress(true);
          setUploadingConversationId(conversation.id);
          setUploadingFileId(createdFileEntry.id);
          setUploadProgress(0);
          const objToUpload = {
            documentIds: [{ id: fileId }],
            accessToken: gDriveToken,
            fileKeys: [fileKey],
          };
          await uploadDriveFile({
            googleDriveObject: objToUpload,
            conversationId: conversation.id,
            createdFiles: [createdFileEntry.id],
            googleDriveApi,
            fileApi,
            setIsUploadInProgress,
            cancelFileUpload,
            addToast,
            i18n,
          });
        }
      } catch (e) {
        const url = await googleDriveApi.getConnectionUrl(`${location.origin}/chat`);
        window.open(url, '_self');
      }
    } else {
      const url = await googleDriveApi.getConnectionUrl(`${location.origin}/chat`);
      window.open(url, '_self');
    }
  };

  const handleAddChat = async () => {
    if (loading) {
      return;
    }
    try {
      setLoadingChatConversationId(conversationId);
      if (messageInput.includes('https://docs.google.com/')) {
        await addGoogleDriveLinkMessage(messageInput);
      } else {
        // if the user did not explicitly click yes or no, we automatically not enable it
        if (dataAnalysisEnablingPending) {
          await addDataAnalysisBotChat(i18n.t('conversation.dataAnalysis.notEnabled'));
          setDataAnalysisEnablingPending(false);
        }
        await addChat(messageInput);
      }
    } catch (e) {
      console.log('e ------------------->> ', e);
      addToast(i18n.t('common.somethingWentWrong'), { appearance: 'error' });
    } finally {
      setLoadingChatConversationId('');
      focusOnInput();
    }
  };

  const assignFilesToConversation = async (
    files: {
      id: string;
      name: string;
    }[],
    conversationToBeAssignedFiles?: Conversation
  ) => {
    let conversation: Conversation | undefined = conversationToBeAssignedFiles;

    const embeddingModel = {
      ...(options.find(item => item.isDefaultFileModel === true) || {}),
      accuracy: DEFAULT_SETTINGS.accuracy,
      length: DEFAULT_SETTINGS.length,
    };

    if (currentConversation && !currentConversation.id && !conversation?.id) {
      conversation =
        (await createConversation({
          type: ConversationTypes.Chat,
          model: embeddingModel.modelVersion as string,
          temperature: embeddingModel.accuracy,
          maxTokens: embeddingModel.length,
          providerName: embeddingModel.providerName,
          chat: {
            files: files,
          },
        })) || INITIAL_CONVERSATION;
    } else {
      conversation = await addFileToConversation(currentConversation.id || conversation?.id || '', files);
    }

    return conversation;
  };

  // Finding SQL model to be used when user clicks on DB icon
  const sqlModel = organizationModels.find(model => model.modelVersion === Models.SQL_MODEL.version);

  // function for setting SQL model as selected model and setting integration data
  const handleTooltipClicked = (configuration: any) => {
    if (sqlModel) {
      setShowDatabaseTooltip(false);
      setSelectedModel(sqlModel);
      setSelectedSqlConnection(configuration);
      setPrimaryConversationModelSettings({
        accuracy: 0.8,
        length: 1024,
        model: sqlModel?.modelVersion as string,
        providerName: sqlModel?.providerName as string,
        modelDisplayName: sqlModel?.displayName as string,
      });
    } else {
      // showing error message if sql model is not found
      setShowDatabaseTooltip(false);
      addToast(i18n.t('adminPortal.tabs.integrations.sqlModel.notFound'), { appearance: 'error' });
    }
  };

  const handleTooltipOpen = () => {
    getIntegrations(); // calling this because we dont have to refresh
    setShowDatabaseTooltip(!showDatabaseTooltip);
  };

  const handleTooltipClose = () => {
    setShowDatabaseTooltip(false);
  };

  //calling this use effect to get all the available integrations
  useEffect(() => {
    getIntegrations();
  }, []);

  // finding active SQL Server integration
  const sqlServerIntegration = integrations?.find(
    integration => integration?.serviceName === SUPPORTED_INTEGRATIONS.SQL_SERVER && integration.isActive === true
  );

  const inputDisabled =
    !doesChatBelongToCurrentUser ||
    loading ||
    (loadingChatConversationId && loadingChatConversationId === conversationId) ||
    (isFileModel &&
      Array.isArray(files) &&
      files.length > 0 &&
      files.some(file => file.status !== fileStatus.DONE) &&
      !isStartedFromKnowledgeBase &&
      !knowledgeBaseSselectedFile) ||
    (isFileModel && !conversationId);

  return (
    <StyledInputContainer>
      <StyledInputElement
        sx={{ padding: '8px' }}
        disableUnderline
        multiline
        maxRows={5}
        id="Chat-Input"
        ref={inputRef}
        placeholder={
          isFileModel && !conversationId
            ? i18n.t('conversation.input.uploadFilePlaceholder')
            : doesChatBelongToCurrentUser
            ? textAreaPlaceholder
            : 'This is not your conversation. You cannot type messages here. Feel free to start a new one.'
        }
        value={messageInput}
        onChange={event => setMessageInput(event.target.value)}
        disabled={inputDisabled}
        onKeyDown={({ key, shiftKey }) => {
          // if shift and enter are pressed, default behaviour should happen(new line in text box)
          if (key === 'Enter' && shiftKey) {
            return;
          }

          if (key === 'Enter') {
            toggleIsPromptsDrawerExpanded(false);
            id ? handleAddChat() : handleCreateConversation();
          }
        }}
      />
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Flex>
          {/*<StyledIconButton>*/}
          {/*  <AddOutlinedIcon sx={{ width: '20px', height: '20px', fill: GRAY_COLORS.GRAY_6 }} />*/}
          {/*</StyledIconButton>*/}

          <UploadDocument
            disabled={noFileModelEnabled}
            disabledMessage={i18n.t('conversation.noFileModel')}
            assignFilesToConversation={assignFilesToConversation}
            options={options}
            onUploadComplete={() => setMessageInput(i18n.t('conversation.file.defaultPrompt'))} // Setting default prompt when file in uploaded
          />

          <StyledIconButton onClick={() => toggleIsPromptsDrawerExpanded()}>
            <LightbulbOutlinedIcon sx={{ width: '20px', height: '20px', fill: GRAY_COLORS.GRAY_6 }} />
          </StyledIconButton>

          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <StyledTooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={showDatabaseTooltip}
                disableFocusListener
                disableHoverListener
                title={
                  // this is for showing number of database connections
                  <div style={{ maxHeight: '200px', overflow: 'auto' }}>
                    {sqlServerIntegration ? (
                      <>
                        <IconButton sx={{ padding: 0, margin: '8px 0px' }}>
                          <img src={SqlServer} alt={'sql-server-logo'} style={{ width: '2rem', marginRight: '4px' }} />
                          <Typography variant="h6" sx={{ fontSize: '14px', fontWeight: 700, color: COLOR_WHITE }}>
                            {i18n.t('adminPortal.tabs.integrations.sqlConnection')}
                          </Typography>
                        </IconButton>
                        {sqlServerIntegration.settings.map((setting: any, index: number) => (
                          <MuiBox
                            key={index}
                            sx={{
                              color: COLOR_WHITE,
                              borderRadius: '4px',
                              padding: '8px',
                              margin: '8px',
                              marginLeft: '0px',
                              fontSize: '14px',
                              '&:hover': {
                                backgroundColor: 'rgba(56, 113, 224, 0.2)',
                              },
                            }}
                            onClick={() =>
                              sqlServerIntegration ? handleTooltipClicked(setting.configuration) : handleTooltipClose
                            }
                          >
                            {setting.configuration.configName}
                          </MuiBox>
                        ))}
                      </>
                    ) : (
                      <Typography variant="h6" sx={{ fontSize: '14px', fontWeight: 700, color: COLOR_WHITE }}>
                        {i18n.t('adminPortal.tabs.integrations.notAvailable')}
                      </Typography>
                    )}
                  </div>
                }
                placement="top-start"
                arrow
                sx={{ cursor: 'pointer' }}
              >
                <StyledIconButton disabled={hasUserStartedAskingQuestions} onClick={() => handleTooltipOpen()}>
                  <StorageIcon
                    sx={{
                      width: '20px',
                      height: '20px',
                      fill: hasUserStartedAskingQuestions ? COLOR_BUTTON_CONTAINED_DISABLED : GRAY_COLORS.GRAY_6,
                    }}
                  />
                </StyledIconButton>
              </StyledTooltip>
            </div>
          </ClickAwayListener>
        </Flex>

        {inputDisabled || !messageInput.trim().length ? (
          <IconButton disabled>
            <img src={SendIconGray} alt={'send-icon-disabled'} />
          </IconButton>
        ) : (
          <PrimaryIconButton
            onClick={() => {
              toggleIsPromptsDrawerExpanded(false);
              id ? handleAddChat() : handleCreateConversation();
            }}
            id="Chat-Button"
          >
            <img src={SendIconWhite} alt={'send-icon'} />
          </PrimaryIconButton>
        )}
      </Flex>
    </StyledInputContainer>
  );
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: GRAY_COLORS.GRAY_500,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: GRAY_COLORS.GRAY_500,
    color: COLOR_WHITE,
    fontSize: '0.875rem',
    borderRadius: '4px',
    maxWidth: '300px',
    padding: '6px 8px',
  },
}));

const StyledInputElement = styled(Input)`
  width: 100%;
  max-height: 100px !important;

  & .MuiInputBase-input {
    max-height: 100px !important;
  }
`;

const StyledIconButton = styled(IconButton)`
  padding: 6px;
`;

const StyledInputContainer = styled(Box)`
  padding: 8px;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid ${GRAY_COLORS.GRAY_2};
`;

export default observer(MessageInput);
