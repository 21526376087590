import { CallTranscriptResultInterface } from '../CallTranscriptResultsPage';
import { FortressResultInterface } from '../FortressResultsPage';
import { DynamicJsonObject } from '../FieldsExtractionResultPage';
import { ResumeParserFileResult, QueryResponseFields } from '../ResumeParserResultsPage';
import { CreditSpreadingResult } from '../../CreditSpreadingResultPage';

export const isCallTranscriptResult = (obj: any): obj is CallTranscriptResultInterface[] => {
  return (
    Array.isArray(obj) &&
    obj.every(
      item =>
        item &&
        typeof item.FileID === 'string' &&
        typeof item.ResponseSummary === 'string' &&
        Array.isArray(item.QueryResponses) &&
        item.QueryResponses.every((qr: any) => qr && typeof qr.question === 'string' && typeof qr.response === 'string')
    )
  );
};

export const isFortressResultInterface = (obj: any): obj is FortressResultInterface => {
  return (
    obj &&
    typeof obj === 'object' &&
    Object.values(obj).every(
      item =>
        item &&
        typeof item === 'object' &&
        Array.isArray((item as any).meta) &&
        typeof (item as any).final_answer === 'string' &&
        typeof (item as any).question === 'string'
    )
  );
};

export const isDynamicJsonObject = (obj: any): obj is DynamicJsonObject => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  return Object.values(obj).every(value => Array.isArray(value) && value.every(item => typeof item === 'string'));
};

export const isHtmlString = (obj: any): obj is string => {
  return typeof obj === 'string' && /<\/?[a-z][\s\S]*>/i.test(obj);
};

export const isCreditSpreading = (obj: any): obj is CreditSpreadingResult => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  return Object.values(obj).every((item: any) => {
    return item && typeof item === 'object'
      ? typeof item.value === 'number' &&
          (!item.ground_truth || typeof item.ground_truth === 'string') &&
          (typeof item.page === 'string' || typeof item.page === 'number') &&
          typeof item.reason === 'string'
      : typeof item === 'number';
  });
};

// RESUME PARSER
export const resumeParserFields = [
  'ACT Score',
  'SAT Score',
  'GPA',
  'Graduation Year',
  'TotalExperience',
  'priorexpIndex',
];

const isResumeParserResponseFields = (obj: any): obj is QueryResponseFields => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  return resumeParserFields.every(key => {
    return key in obj;
  });
};

const isResumeParserResponse = (obj: any): obj is ResumeParserFileResult => {
  if (obj && typeof obj.FileID === 'string' && typeof obj.QueryResponses === 'string') {
    try {
      const queryResponses = JSON.parse(obj.QueryResponses);
      const a = isResumeParserResponseFields(queryResponses);

      return a;
    } catch {
      return false;
    }
  }
  return false;
};

export const isResumeParserResponseArray = (obj: any): obj is ResumeParserFileResult[] => {
  return Array.isArray(obj) && obj.every(item => isResumeParserResponse(item));
};
